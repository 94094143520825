import { populateTradeFromURL, saveOriginalReferrerCookie } from '../../../helpers';
/**
 * Init Pro Signup form
 */
export function initContractorForm() {
	window.modForm.opts.formTyCls = 'form--ty';
	window.modForm.opts.submitData = {};
	window.modForm.opts.sessionObj = {};
	window.modForm.opts.landingPage = window.modUtils.getLandingPage();
	window.modForm.opts.referrerCookieName = 'original_referrer';
	window.modForm.opts.referrerCookieExpiration = 60 * 24 * 30;

	saveOriginalReferrerCookie();
	populateTradeFromURL();
}
