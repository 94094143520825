/* globals modForm modUtils Modalytics */
/**
 * Endpoint Domains:
 * Dev: ivr.dev.quinstreet.net
 * Stage: ivr.quinstage.com
 * Prod: ivr.quinstreet.com
 */
/**
 * This function gets the phone assignment data from the quest api.
 * @param {object} trafficDetails - Accepts response object of `TrafficDetails` api.
 * @returns {void}
 */
export const getPhoneAssignmentData = function(trafficDetails) {
	const currentParams = modUtils.getUrlParamsToObject();
	const campaignAttributes = ['ad', 'gclid', 'msclkid', 'vmcid', 'tbcid', 'fb_ad_id', 'fb_campaign_id', 'fbclid', 'fb_adset_id'];
	const { CLK, tagId } = trafficDetails;
	const { vendorKey, PartnerSourceID } = currentParams;
	const TradingPlatformTrackingInfo = {};
	const isProductionEnv = modUtils.getEnv() === 'production';
	const phoneNumberRegex = new RegExp(/(\d{3})(\d{3})(\d{4})/g);
	const phoneNumberRegexWithDash = new RegExp(/(\d{3})-(\d{3})-(\d{4})/gmi);
	const phoneNumberContainers = document.querySelectorAll(modForm.opts.phoneAssignmentConfig.phoneNumberContainerSelector);
	const phoneNumberLinks = document.querySelectorAll(modForm.opts.phoneAssignmentConfig.phoneNumberLinksSelector);
	const hidePhoneNumberContainers = () => {
		if (phoneNumberContainers && phoneNumberContainers.length) {
			phoneNumberContainers.forEach((container) => container.style.display = 'none');
		}
	};

	const apiDomain = `https://${isProductionEnv ? 'ivr.quinstreet.com' : 'ivr.quinstage.com'}`;
	const url = `${apiDomain}/number`;

	const requestHeaders = new Headers();
	requestHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
	requestHeaders.append('Accept', 'application/json');

	const payload = {
		SourceClickKey: CLK || '',
		LandingQuadTagID: tagId || '',
		PC: modForm?.getFormData()?.zip,
		PortalServiceCode: modForm.opts.vertical,
		service: 'assign',
		BusinessUnit: 'FC',
		QS_CATEGORY: 'Home Services',
		TradingPlatformTrackingInfo: '',
		vendorKeys: vendorKey ? vendorKey : '-1',
		quadTagId: isProductionEnv ? modForm.opts.phoneAssignmentConfig.questQuadTagId.prod : modForm.opts.phoneAssignmentConfig.questQuadTagId.staging,
		AffiliateSubID: PartnerSourceID || ''
	};

	if (currentParams && Object.keys(currentParams).length) {
		Object.keys(currentParams).forEach(function(key) {
			if (campaignAttributes.includes(key)) {
				TradingPlatformTrackingInfo[key] = currentParams[key];
			}
		});
	}

	payload.TradingPlatformTrackingInfo = JSON.stringify(TradingPlatformTrackingInfo);

	const requestOptions = {
		method: 'POST',
		headers: requestHeaders,
		redirect: 'follow',
		mode: 'cors',
		body: new URLSearchParams(payload).toString()
	};

	fetch(url, requestOptions)
		.then(response => response.json())
		.then(jsonResponse => {
			if (jsonResponse && Object.keys(jsonResponse).length) {
				const phoneNumberData = jsonResponse[payload.vendorKeys];

				if (!phoneNumberData || phoneNumberData.constructor !== Object) {
					hidePhoneNumberContainers();
					return;
				}

				const { ccPhoneNum, extn } = phoneNumberData;
				const shouldDisplayPhoneNumber = (phoneNumberLinks && phoneNumberLinks.length) && (phoneNumberContainers && phoneNumberContainers.length) && ccPhoneNum;

				if (shouldDisplayPhoneNumber) {
					const formattedPhoneNumber = ccPhoneNum.replace(phoneNumberRegex, '$1-$2-$3');
					const formattedPhoneNumberWithExtension = `${formattedPhoneNumber} ${extn ? `Ext: ${extn}` : ''}`;
					phoneNumberLinks.forEach((phoneNumberLink) => {
						phoneNumberLink.setAttribute('href', `tel:${ccPhoneNum}${extn ? `,${extn}` : ''}`);
						const phoneNumberTextNode = phoneNumberLink.querySelector(modForm.opts.phoneAssignmentConfig.phoneNumbersTextSelector);
						if (phoneNumberTextNode) {
							const hasContent = phoneNumberRegexWithDash.test(phoneNumberTextNode.textContent);
							if (hasContent) {
								phoneNumberTextNode.textContent = phoneNumberTextNode.textContent.replace(phoneNumberRegexWithDash, formattedPhoneNumberWithExtension);
							} else {
								phoneNumberTextNode.textContent = formattedPhoneNumberWithExtension;
							}
						}
					});

					Modalytics.heap('track', ['Phone Assignment', {
						phoneNumber: true,
						extension: extn ? true : false
					}]);
				}
			} else {
				Modalytics.heap('track', ['Phone Assignment', {
					phoneNumber: false
				}]);
			}
		})
		.catch(error => {
			hidePhoneNumberContainers();
			console.log('Something went wrong!', error);
			Modalytics.heap('track', ['Phone Assignment', {
				phoneNumber: false
			}]);
		});
};
