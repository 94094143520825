/**
 * Get Forms Service API domain based on whether this is staging or production env
 * @returns {String} API domain to use
 */
export function getFormsServiceApiDomain() {
	let domain = 'https://hsapiservice.quinstage.com/';
	const env = window.modUtils.getEnv();

	if (env === 'production') {
		domain = 'https://form-service-hs.qnst.com/';
	}

	return domain;
}
