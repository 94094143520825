/**
 * Process data to the new API required format
 * @param {Object} formData - form data
 * @param {Number} currentStep - current step index
 * @returns {Object} - form data
 */
export function formatContractorDataForModApi(formData, currentStep) {
	const formattedFormData = {
		0: {
			firstName: formData.firstName,
			lastName: formData.lastName,
			primaryProjectClass: formData.primaryProjectClass,
			multipleTrades: formData.multipleTrades ? 1 : 0,
			contractorNetwork: formData.contractorNetwork ? 1 : 0,
			companyName: formData.companyName,
			howDidYouHearAboutUs: formData.howDidYouHearAboutUs,
			clientReferrerSource: formData.clientReferrerSource,
			phone: formData.homePhone,
			email: formData.email,
			bbdId: formData.bbdId,
			attribution: {
				/* eslint-disable camelcase */
				utm_source: formData.utm_source,
				utm_campaign: formData.utm_campaign,
				utm_medium: formData.utm_medium,
				utm_term: formData.utm_term,
				utm_content: formData.utm_content,
				/* eslint-enable camelcase */
				program: formData.program,
				gclId: formData.gclid,
				keyword: formData.keyword,
				landingPage: formData.landingPage,
				elqCustomerGUID: formData.elqCustomerGUID,
				elqCookieWrite: formData.elqCookieWrite
			},
			compliance: {
				wasTcpaShown: formData.tcpa,
				tcpaText: formData.tcpaText,
				certificationToken: formData.certificationToken,
				certificationType: formData.certificationType
			},
			modSessionId: formData.modSessionId
		},
		1: {
			annualRevenue: formData.annualRevenue,
			buysLeads: formData.buysLeads,
			crmInUse: formData.crmInUse,
			ein: formData.ein,
			whoCallsLeads: formData.whoCallsLeads,
			websiteUrl: formData.websiteUrl,
			companyZip: formData.companyZip
		}
	};

	// Check if this new data to submit hasn't been submitted yet
	const currentStepData = formattedFormData[currentStep];
	if (currentStepData && Object.entries(currentStepData).length) {
		Object.entries(currentStepData).map(function([name, value]) {
			if (value) {
				window.modForm.opts.submitData[name] = value;
			}
		});
	}

	return window.modForm.opts.submitData;
}
